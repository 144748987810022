import * as clients from './configs';
import { Features } from './type';
import defaultFeatureState from './default';
import { isCapacitor } from '@zignaly-open/ui';

const { VITE_DEV_ONLY_WHITELABEL: whitelabelNameOverride } = import.meta.env;

export const whitelabel =
  window.__zignalyWhitelabelConfig ||
  (whitelabelNameOverride && clients[whitelabelNameOverride]) ||
  clients.zignaly;

export const isZignaly = whitelabel.slug === 'z01';

export const isFeatureOn = (feature: Features): boolean => {
  return (
    {
      ...defaultFeatureState,
      ...(whitelabel?.featureOverrides || {}),
    }[feature] || false
  );
};

export const isAccountRequired =
  isFeatureOn(Features.LoginOnlyAccess) || isCapacitor;

export function getMinInvestmentAmount(coin: string): number {
  if (!isFeatureOn(Features.MinInvestment)) return 0;
  return +whitelabel.minInvestment?.[coin] || 0;
}
