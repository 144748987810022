import { styled } from "@mui/material";

export const ZigSwitchLightDarkContainer = styled("button")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: all 500ms;
  &:hover {
    background-color: transparent;
  }
`;
