import React, { useEffect } from 'react';
import { ZModal as ZModalBase } from '@zignaly-open/ui';
import AuthenticatedWall from '../../util/walls/AuthenticatedWall';
import {
  useIsAuthenticated,
  useMaybeMakeSureSessionIsAlive,
} from '../../apis/user/use';
import { ZModalProps } from './types';

const ZModal: React.FC<ZModalProps> = ({ children, allowUnauth, ...props }) => {
  const isAuthenticated = useIsAuthenticated();
  useMaybeMakeSureSessionIsAlive(!allowUnauth);
  const notLoggedInWhenNeeded = !allowUnauth && !isAuthenticated;

  useEffect(() => {
    notLoggedInWhenNeeded && setTimeout(props.close);
  }, [notLoggedInWhenNeeded]);

  return (
    <ZModalBase {...props}>
      {notLoggedInWhenNeeded ? <AuthenticatedWall /> : <>{children}</>}
    </ZModalBase>
  );
};

export default ZModal;
