// Loosely based on https://dev.to/atonchev/flawless-and-silent-upgrade-of-the-service-worker-2o95
import { useEffect } from 'react';

async function getWaitingWorker() {
  const registrations =
    (await navigator?.serviceWorker?.getRegistrations()) || [];
  const registrationWithWaiting = registrations.find((reg) => reg.waiting);
  return registrationWithWaiting?.waiting;
}

async function maybeAskToSkipWaiting(): Promise<void> {
  return (await getWaitingWorker())?.postMessage({
    // in the original article we were prompted to do so only when there's a single tab
    // but this approach works for us
    type: 'SKIP_WAITING',
  });
}

export function useMaybeUpdateServiceWorkerOnClose(): void {
  useEffect(() => {
    window.addEventListener('beforeunload', async () => {
      await maybeAskToSkipWaiting();
    });
  }, []);
}

export const isInStandaloneMode =
  window.matchMedia?.('(display-mode: standalone)').matches ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.navigator.standalone ||
  document.referrer.includes('android-app://');

export function disablePwaZoom(): void {
  const cancelZoom = (e: Event, zoom = 1) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.body.style.zoom = zoom;
  };

  if (isInStandaloneMode) {
    document.addEventListener('gesturestart', (e) => cancelZoom(e, 0.999));
    document.addEventListener('gesturechange', (e) => cancelZoom(e, 0.999));
    document.addEventListener('gestureend', (e) => cancelZoom(e, 1));
  }
}
