import { RootState } from './store';
import { Mutex } from 'async-mutex';
import { setSessionExpiryDate } from './user/store';
import { SessionResponse } from './user/types';
import { TIME_TO_START_REFRESHING_TOKEN } from '../util/constants';
import i18next from '../util/i18n/i18next';
import { backendError } from 'util/hooks/useToast';
import { BackendError } from '../util/errors';
import { clearUserSession } from './user/util';
import { whitelabel } from '../whitelabel';
import { useToast as getToastUi } from '@zignaly-open/ui';
import * as Sentry from '@sentry/react';
import { NetworkError } from './util';
import {
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchArgs,
  BaseQueryApi,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

const mutex = new Mutex();

const baseQuery = (baseUrl = whitelabel.baseApi) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).user.accessToken;
      const { slug: xSource } = whitelabel;
      xSource && headers.set('x-source', xSource);
      if (token && !['login', 'signup', 'marketplace'].includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (!headers || !headers.get('Content-Type')) {
        headers.set('content-type', 'application/json');
      }
      return headers;
    },
    credentials: baseUrl === whitelabel.baseApi ? 'include' : 'same-origin',
  });

const endpointsWhitelistedFor401 = [
  'user/verify_code/enable_user',
  'user/verify_code/verify_email',
  `user/verify_2fa`,
  `user/resend_code/enable2FA`,
  `user/resend_code/disable2FA`,
  `known_device/verify`,
  `login`,
  `logout`,
  'change_password',
];

const endpointsWhitelistedForSessionRefresh = [
  'user/enable_2fa/step2',
  `logout`,
];

const maybeReportError = (
  error: FetchBaseQueryError,
  api: BaseQueryApi,
  request: Request,
) => {
  if (!error) return;
  if (!navigator.onLine) {
    getToastUi().error(i18next.t('error:error.offline'), {
      toastId: 'offline',
    });
    return;
  }
  // eslint-disable-next-line no-console
  console.error(error);

  const errorMsg = backendError(i18next.t, error as unknown as BackendError);

  if (errorMsg) {
    const fingerprint = [
      String(error.status),
      ...(!['PARSING_ERROR', 'FETCH_ERROR'].includes(error.status as string)
        ? [api.endpoint, request?.method]
        : []),
    ];
    const netError = new NetworkError(error, request);
    if (errorMsg === i18next.t('error:something-went-wrong')) {
      netError.message = 'Generic "Something went wrong"';
    }

    Sentry.captureException(netError, {
      extra: { error: error.data },
      contexts: {
        api: {
          endpoint: api.endpoint,
        },
      },
      level: 'info',
      fingerprint,
    });
  }
};

const customFetchBase: (
  baseUrl?: string,
) => BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { silent?: boolean }
> = (baseUrl) => async (args, api, extraOptions) => {
  const result = await baseQuery(baseUrl)(args, api, extraOptions);

  extraOptions?.silent ||
    maybeReportError(result?.error, api, result?.meta?.request);
  const error = result?.error as FetchBaseQueryError & BackendError;

  if (
    result?.error?.status === 401 &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !endpointsWhitelistedFor401.includes(args.url) &&
    // Some endpoints are protected by 2FA/code verification, so we don't want to log out the user in those cases
    ![37, 48, 1086].includes(error.data?.error?.code)
  ) {
    clearUserSession(api.dispatch);
  } else if (
    +new Date((api.getState() as RootState).user.sessionExpiryDate) -
      TIME_TO_START_REFRESHING_TOKEN <
      Date.now() &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !endpointsWhitelistedForSessionRefresh.includes(args.url)
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery()(
          { url: 'user/session' },
          api,
          extraOptions,
        );

        api.dispatch(
          setSessionExpiryDate(
            (refreshResult?.data as SessionResponse)?.validUntil,
          ),
        );
      } finally {
        release();
      }
    }
  }

  return result;
};

export default customFetchBase;
