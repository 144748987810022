import React from "react";
import { Modal as MuiModal } from "@mui/material";
import { LoaderContainer } from "./styles";
import { ZModalProps } from "./types";
import ZigModalContainer from "../ZigModalContainer";
import { Loader } from "../Loader";

const ZModal: React.FC<ZModalProps> = ({
  close,
  isLoading,
  disableBackdropClose,
  children,
  onGoBack,
  title,
  width,
  wide,
  titleAlign,
  titleStyles,
  mobileFullScreen,
  ...props
}): React.ReactElement => {
  return (
    <MuiModal
      {...props}
      onClose={(event, reason) => {
        if (disableBackdropClose && reason === "backdropClick") {
          return;
        }
        close?.();
      }}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        ...(props.sx ?? {}),
      }}
    >
      <ZigModalContainer
        width={width || (wide && 620) || undefined}
        title={title}
        onClickClose={close}
        mobileFullScreen={mobileFullScreen}
        onGoBack={onGoBack}
        titleAlign={titleAlign}
        titleStyles={titleStyles}
      >
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>{children}</>
        )}
      </ZigModalContainer>
    </MuiModal>
  );
};

export default ZModal;
