// @ts-ignore
const isQWCrutch = window.__zignalyWhitelabelConfig?.slug === 'uni_01';

export const allowedDeposits = {
  spot: isQWCrutch ? ['USDT', 'ETH', 'BNB', 'BTC'] : ['USDT'],
  futures: ['USDT'],
};

export const allowedCoinsSwapTo = {
  spot: ['USDT'],
  futures: ['USDT'],
};

export const restrictCoinsSwapFrom = {
  spot: ['USDT'],
  futures: ['USDT'],
};

export const allowedNetworks = isQWCrutch
  ? null
  : {
      spot: ['BSC'],
    };
