export const openIntercom = () => {
  const intercomLauncher = document.querySelector(
    '.intercom-launcher',
  ) as HTMLElement;
  const iframeLauncher = document.querySelector(
    '.intercom-launcher-frame',
  ) as HTMLIFrameElement;

  if (intercomLauncher) {
    intercomLauncher.click();
  } else {
    const iframeContent = iframeLauncher?.contentDocument?.querySelector(
      '.intercom-launcher',
    ) as HTMLElement;
    iframeContent?.click();
  }
};
