import { zignalyUiI18nAllTranslations } from '@zignaly-open/ui/i18n';

export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  request: async (options, url, payload, callback) => {
    const [lng, ns] = url.split('/');
    const returnFailure = (consoleOutput: unknown) => {
      // eslint-disable-next-line no-console
      console.error(consoleOutput);
      callback({}, { status: 404 });
    };

    const returnSuccess = (data: Record<string, unknown>) => {
      callback(null, {
        data: JSON.stringify(data),
        status: 200,
      });
    };

    if (!/^[a-z\d-]+$/.test(ns) || !/^[a-z\d-]+$/.test(lng)) {
      return returnFailure('Why do you have those chars in the ns/language?');
    }

    if (ns === 'zignaly-ui') {
      if (zignalyUiI18nAllTranslations?.[lng]) {
        return returnSuccess(zignalyUiI18nAllTranslations?.[lng]);
      } else {
        return returnFailure(`No translation for ${lng} in zignaly-ui`);
      }
    }

    if (ns === 'links-overrides') {
      // for the record, I told them it was a bad idea
      // and the links should eb a part of the wl config rather than translations
      return returnSuccess({});
    }

    try {
      const { default: data } = await import(`../../locales/${lng}/${ns}.json`);
      return returnSuccess(data);
    } catch (e) {
      return returnFailure(e);
    }
  },
  loadPath: '{{lng}}/{{ns}}',
};
