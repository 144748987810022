import React, { useCallback, useEffect } from 'react';
import { VERSION_CHECK_INTERVAL } from './constants';
import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { useZAlert } from '../../ZModal/use';
import { useTranslation } from 'react-i18next';

const isUpdateNeeded = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (Capacitor.getPlatform() === 'android') {
    return result.availableVersionCode !== result.currentVersionCode;
  } else {
    return result.availableVersionName !== result.currentVersionName;
  }
};

const CapacitorUpdateChecker: React.FC = () => {
  const { t } = useTranslation('common');
  const showAlert = useZAlert();
  const checkIfUpdateIsNeeded = useCallback(() => {
    isUpdateNeeded()
      .then((v) => {
        if (v) {
          showAlert({
            title: t('common:capacitor-new-version.title'),
            okLabel: t('common:capacitor-new-version.cta'),
            description: t('common:capacitor-new-version.description'),
            okAction: () => AppUpdate.openAppStore(),
          });
        } else {
          setTimeout(() => checkIfUpdateIsNeeded(), VERSION_CHECK_INTERVAL);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, []);

  useEffect(() => {
    checkIfUpdateIsNeeded();
  }, []);

  return null;
};

export default CapacitorUpdateChecker;
